






















/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { Inject } from '../../../../../support'
import { ImageData } from '../../../../../contexts'

import { ILocaleContentManager, LocaleContentManagerType } from '../../../../shared/services'
import { toImageProps } from '../../../../shared/support'

import { Variant, VariantsSwitchProps } from '../VariantsSwitch.contracts'
import { VariantSwitchMixin } from '../VariantSwitch.mixin'

@Component({ name: 'Default' })
export class Default extends VariantSwitchMixin {
  @Inject(LocaleContentManagerType, false)
  protected readonly localeContentManager?: ILocaleContentManager

  @PropSync('model', { required: false, default: null })
  public _model!: VariantsSwitchProps['model']

  @Prop({ type: Array, required: false })
  public variants?: Variant[]

  public get displayUnavailableVariants (): boolean {
    if (!this.localeContentManager) {
      return false
    }

    const productLocaleConfig = this.localeContentManager?.retrieve<Record<string, unknown>>('product')

    return Object.prototype.hasOwnProperty.call(productLocaleConfig, 'showUnavailableVariants') && !!productLocaleConfig.showUnavailableVariants
  }

  public get hasIcon (): boolean {
    return !!this.config && !!this.config.switchIcon
  }

  public getStyle (identifier: string | ImageData): AnyObject {
    if (typeof identifier === 'string') {
      return { backgroundColor: identifier }
    }

    return { backgroundImage: `url(${toImageProps(identifier).src})` }
  }
}

export default Default
