import { Component, Mixins } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import { Inject, logger } from '../../../../support'

import { User } from '../../../profile/models/user'
import { IToastMixin, ToastMixin } from '../../../shared'

import { IWishList, WishListServiceType } from '../../services/wishlist'
import { WishListItemInput, WishListModel } from '../../contract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface IBaseWishListMixin {
  add (item: WishListItemInput): Promise<void>
  isInWishlist (sku: string): boolean
  isServiceAvailable: boolean
  remove (item: string): Promise<void>
  wishlist: WishListModel | null
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'BaseWishListMixin' })
export class BaseWishListMixin extends Mixins<IToastMixin>(ToastMixin) implements IBaseWishListMixin {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<User>

  @Inject(WishListServiceType, false)
  protected readonly wishListService?: IWishList

  /**
   * Determines whether service is available
   */
  public get isServiceAvailable (): boolean {
    return typeof this.wishListService !== 'undefined'
  }

  /**
   * Determines the wishlist of the user/guest
   */
  public get wishlist (): WishListModel | null {
    return this.$store.getters['wishlist/wishlist']
  }

  /**
   * Determines the total count of elements inside the list.
   */
  public get totalCount (): number {
    if (!this.wishlist) return 0
    return this.wishlist.itemsCount
  }

  /**
   * Handles adding to the list new item.
   */
  public async add (item: WishListItemInput): Promise<void> {
    if (!this.isServiceAvailable || !this.wishlist) {
      return
    }

    try {
      const wishlist = await this.wishListService?.add(this.wishlist.id, item)
      this.$store.commit('wishlist/setWishList', wishlist)
    } catch (e) {
      logger(e, 'error')
      this.showToast((e as Error).message, 'danger')
    }
  }

  /**
   * Handles removing item from the list.
   */
  public async remove (sku: string): Promise<void> {
    if (!this.isServiceAvailable || !this.wishlist) {
      return
    }

    const toRemove: string = this.wishlist.items.filter((item) => item.product.sku === sku)[0].id

    try {
      const wishlist = await this.wishListService?.remove(this.wishlist.id, toRemove)
      this.$store.commit('wishlist/setWishList', wishlist)
    } catch (e) {
      logger(e, 'error')
      this.showToast((e as Error).message, 'danger')
    }
  }

  /**
   * Determines whether the product under the passed-in argument is in wishlist.
   */
  public isInWishlist (sku: string): boolean {
    if (!this.wishlist) {
      return false
    }

    return this.wishlist.items.some((item) => item.product.sku === sku)
  }
}
export default BaseWishListMixin
