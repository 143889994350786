


























import { Component, Prop, Vue } from 'vue-property-decorator'

import { benefitLogo, MILES_PER_PLN } from '../../shared'
import { BenefitProgram, LinkData } from '../../contracts/programs'

import { MilesAndMoreCounterProps } from './MilesAndMoreCounter.contracts'
import {
  AbstractLinkProps,
  TabOption
} from '../../../../dsl/abstract/components/Link/Link.contracts'
import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { BenefitPropertyName } from '../../organisms/BenefitPrograms/BenefitPrograms.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<MilesAndMoreCounter>({
  name: 'BenefitProgramPreview',
  created () {
    this.composeProgramLink()
  }
})
export class MilesAndMoreCounter extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: false })
  public readonly text?: MilesAndMoreCounterProps['text']

  @Prop({ type: Number, required: true })
  public readonly total?: MilesAndMoreCounterProps['total']

  public readonly logo: string = benefitLogo[BenefitProgram.MilesAndMore]

  public count: number = this.total ? Math.floor(this.total * MILES_PER_PLN) : 0
  public label: string =
    this.text ?? this.$t('front.loyalty.molecules.MilesAndMoreCounter.text').toString()

  public link: LinkData | null = null

  protected composeProgramLink (): void {
    if (!this.siteService) {
      return
    }

    const properties = this.siteService.getActiveSite().properties
    for (const [key, value] of Object.entries(properties)) {
      if (key === BenefitPropertyName.MilesMore) {
        this.link = {
          label: key,
          target: value as string,
          tabTarget: TabOption.Blank,
          title: key
        }
      }
    }
  }
}

export default MilesAndMoreCounter
