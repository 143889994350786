






/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Mixins, Prop, PropSync, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import {
  Variant,
  VariantsSwitchProps,
  VariantsSwitchType,
  variantsSwitchTypeRegistry
} from './VariantsSwitch.contracts'
import {
  VARIANT_SWITCH_COMPONENT_KEY,
  VARIANT_SWITCH_CONFIG_MAP,
  VariantSwitchConfig
} from './VariantSwitch.config'
import { StructureConfigurable } from '../../../../support/mixins'

@Component<VariantsSwitch>({
  name: 'VariantsSwitch',
  created () {
    this.config = this.getComponentConfig(VARIANT_SWITCH_COMPONENT_KEY, { ...VARIANT_SWITCH_CONFIG_MAP })
  }
})
export class VariantsSwitch extends Mixins(StructureConfigurable) {
  @PropSync('model', { required: false, default: null })
  public _model!: VariantsSwitchProps['model']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isFaF!: boolean

  @Prop({ type: Array, required: false })
  public variants?: Variant[]

  @Prop({ type: String, required: false, default: VariantsSwitchType.Default })
  public type?: string

  public config!: VariantSwitchConfig

  /**
   * Get component by type.
   */
  public get component (): VueConstructor {
    if (typeof this.type === 'undefined') {
      return variantsSwitchTypeRegistry[VariantsSwitchType.Default]
    }

    return variantsSwitchTypeRegistry[this.type]
  }
}

export default VariantsSwitch
