import { BenefitProgram } from '../../contracts/programs'

export const BENEFIT_PROGRAMS_COMPONENT_KEY = 'BenefitProgramsComponent'

export const defaultComponentConfig = {
  fixedPrograms: [BenefitProgram.FriendsAndFamily]
}

export enum BenefitPropertyName {
  Kameleon = 'kameleon',
  MilesMore = 'milesMore'
}
